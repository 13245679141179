import { PageProps } from 'gatsby';

import { useCustomMixpanel } from '@services/Mixpanel';
import { ConfirmationPage } from '@src/modules';
import useEpdCover from '@hooks/useEpdCover';

const EpdCoverConfirmation = (props: PageProps) => {
  useEpdCover();
  const mixpanel = useCustomMixpanel();
  return <ConfirmationPage {...props} mixpanel={mixpanel} />;
};

export default EpdCoverConfirmation;
